import {createContext, useEffect, useReducer, useState} from "react";
import {defaultProjectData} from "../config";
import PropTypes from "prop-types";
import axios from '../utils/axios'

const initialState = {
    projectData: null,
}

const handlers = {
    UPDATE_PROJECT_DATA: (state, action) => {
        const { projectData } = action.payload
        return {
            ...state,
            projectData: {
                ...projectData,
            }
        }
    },
}

const reducer = (state, action) => {
    return handlers[action.type] ? handlers[action.type](state, action) : state
}

const ProjectDataContext = createContext({
    ...initialState,
    updateProjectDataKey: () => Promise.resolve(),
    createProjectData: () => Promise.resolve(),
});

ProjectDataProvider.propTypes = {
    children: PropTypes.node,
}

function ProjectDataProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState)
    // Use effect will run once on component mount. It will run once because of the empty array [] in
    // useEffect's second argument. When it runs, it will try to get a settings object.
    // If it fails, it will set defaults.
    useEffect(() => {
        const initialize = async () => {
            try {
                const response = await axios.get('api/v1/project-data/project-data/')
                const {project_data} = response.data;
                dispatch({
                    type: 'UPDATE_PROJECT_DATA',
                    payload: {
                        projectData: project_data,
                    }
                })
            } catch (err) {
                const response = await axios.post(
                    '/api/v1/project-data/project-data/create',
                    {project_data: {...defaultProjectData}},
                )
                const {project_data} = response.data;
                dispatch({
                    type: 'UPDATE_PROJECT_DATA',
                    payload: {
                        projectData: {...project_data},
                    }
                })
            }
        }
        initialize();
    }, [])

    const createProjectData = async (projectData) => {
        const response = await axios.post(
            '/api/v1/project-data/project-data/create',
            {project_data: projectData},
        )
        const responseProjectData = response.data['project_data']
        dispatch({
            type: 'UPDATE_PROJECT_DATA',
            payload: {
                projectData: {...responseProjectData},
            }
        });
    }
    const updateProjectDataKey = async (projectDataPartial) => {

        const response = await axios.patch(
            '/api/v1/project-data/project-data/',
            {
                project_data: Object.fromEntries(
                    Object.entries({
                    ...defaultProjectData,
                    ...state.projectData,
                    ...projectDataPartial}).filter(
                        keyAndObject => defaultProjectData.hasOwnProperty(keyAndObject[0])
                    )
                )
            },
        )
        const responseProjectData = response.data['project_data']
        dispatch({
            type: 'UPDATE_PROJECT_DATA',
            payload: {
                projectData: {...responseProjectData},
            }
        });

    }

    return (
        <ProjectDataContext.Provider value={{
            ...state,
            updateProjectDataKey,
            createProjectData,
        }}>
            {state.projectData && children}
        </ProjectDataContext.Provider>
    )

}

export { ProjectDataProvider, ProjectDataContext }